import { useState } from "react";

import useSingleLead from "../../../data/useSingleLead";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";
import { ILead } from "../../../models/Lead";
import Card from "../../shared/Card/Card";

export default function OfficeNotes({ lead }: { lead: ILead }) {
  const { update } = useSingleLead(lead.id);
  const { saveHandlers } = useDefaultCRUDHandlers("Office Notes");
  const [officeNotes, setOfficeNotes] = useState(lead.officeNotes);

  const handleSaveOfficeNotes = () => {
    const updatedLead = {
      ...lead,
      officeNotes: officeNotes
    };

    update.mutate(updatedLead, saveHandlers)
  }

  return (
    <Card title="Office Notes" bodyClassName="p-4">
      <div className="space-y-4">

        <textarea
          className="w-full"
          rows={5}
          defaultValue={lead.officeNotes!}
          onChange={(e) => setOfficeNotes(e.target.value)}
          onBlur={() => handleSaveOfficeNotes()}
        />

        <button
          className="rounded-md border border-transparent bg-gp-blue-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gp-blue-700"
          onClick={() => handleSaveOfficeNotes()}
        >
          Update
        </button>

      </div>
    </Card>
  );
}
