import { useForm } from "react-hook-form";

import { GenericPriceSettingType, ICustomerGenericPriceSetting } from "../../../models/PriceSettings";
import SelectInput from "../../shared/Inputs/SelectInput";
import { TextInput } from "../../shared/Inputs/TextInput";
import GeneralPriceSettingSingle from "../PriceConfiguration/GeneralPriceSettingSingle";
import { ButtonInput } from "../../shared/Inputs/ButtonInput";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";
import usePriceSettingsMutations from "../../../data/usePriceSettingsMutations";
import { useEffect, useState } from "react";


export default function SpecificPriceSingle({ customerPriceConfigurationId, specificPriceSetting }: { customerPriceConfigurationId: string, specificPriceSetting?: ICustomerGenericPriceSetting }) {
  const { createSpecific, updateSpecific, removeSpecific } = usePriceSettingsMutations();
  const { saveHandlers, deleteHandlers } = useDefaultCRUDHandlers("Price Settings");
  const [isLoading, setIsLoading] = useState(true);
  const { handleSubmit, register, setValue, watch, reset } = useForm<ICustomerGenericPriceSetting>({
    defaultValues: {
      id: specificPriceSetting?.id || "",
      customerPriceConfigurationId: customerPriceConfigurationId,
      type: specificPriceSetting?.type || 0,
      value: specificPriceSetting?.value || "",
      priceSetting: {
        value: specificPriceSetting?.priceSetting?.value || 0,
        isFixed: specificPriceSetting?.priceSetting?.isFixed || true
      }
    }
  });

  const enumArray = [
    ...Object.values(GenericPriceSettingType).filter((ct) => isNaN(Number(ct))),
  ];

  const options = enumArray.map((opt, i) => {
    return { id: i, name: opt };
  });


  const onSubmit = (data: any) => {
    if (specificPriceSetting) {
      updateSpecific.mutate(data, saveHandlers);
    } else {
      data.id = null;
      createSpecific.mutate(data, {
        ...saveHandlers,
        onSuccess: () => {
          saveHandlers.onSuccess();
          reset();
        }
      });
    }
  };

  const onRemove = () => {
    if (specificPriceSetting) {
      removeSpecific.mutate(specificPriceSetting.id, deleteHandlers);
    }
  };

  useEffect(() => {
    if (isLoading) {
      if (specificPriceSetting) reset(specificPriceSetting); // Reset the form with data if we are editing
      setIsLoading(false);
    }
  }, [specificPriceSetting]);

  if (isLoading) return null;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex gap-4">

      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-x-4">
          <SelectInput label="Type" register={register} registerName={`type`} options={options} setValue={setValue} watch={watch} cols="w-64" />
          <TextInput label={"Value"} registerName={`value`} register={register} placeholder="Make, Model, Year etc. " cols="w-64" />
        </div>

        <div>
          <GeneralPriceSettingSingle label={"Price Change"} objectName={"priceSetting"} register={register} setValue={setValue} watch={watch} />
        </div>
      </div>

      <div className="flex items-end gap-x-4 pl-8">
        <ButtonInput label="Save" isSubmit />
        {specificPriceSetting && <ButtonInput label="Delete" isSubmit={false} onClick={onRemove} classes="bg-red-600 hover:bg-red-600/80" />}
      </div>
    </form>
  )

}