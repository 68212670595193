export class CollectionReminder {
  static Message = "C6BC1C29-2700-41BA-88B0-C40E972ABE04";
  static Email = "534B4DBC-3220-4093-F62F-08DB262ACEE1";
}

export class DocumentsRequired {
   static Email = "3748E3DE-1AE9-4051-84F4-08DB2AD19047";
}

export class QualifyLead {
 static Message = "B42DD2F0-C715-45C9-FE76-08DB3B655F35";
}