import useNotification from "../components/notifications/useNotifications";


export default function useDefaultCRUDHandlers(objectName) {
  const { addNotification } = useNotification();

  const saveHandlers = {
    onSuccess: () => {
      addNotification({
        variant: "success",
        primaryText: `${objectName} saved`,
      });
    },
    onError: () => {
      addNotification({
        variant: "error",
        primaryText: `Failed to save ${objectName.toLowerCase()}`,
      });
    },
  }

  const deleteHandlers = {
    onSuccess: () => {
      addNotification({
        variant: "success",
        primaryText: `${objectName} deleted`,
      });
    },
    onError: () => {
      addNotification({
        variant: "error",
        primaryText: `Failed to delete ${objectName.toLowerCase()}`,
      });
    },
  }

  return {
    saveHandlers,
    deleteHandlers
  }
}