import { useEffect, useState } from "react";

import { useAuth } from "../../contexts/AuthContext";
import useWilmaUsers from "../../data/useWilmaUsers";
import stringToColour from "../../utils/stringToColour";
import { IWilmaUser } from "./../../models/auth/WilmaUser";

interface AvatarProps {
  classes?: string;
  userId?: string;
}

/**  Returns an avatar for the user
 If a userId is provided as a prop avatar will attempt to look it up
 If no userId is provided it will default to the logged in user */
export default function Avatar({ classes, userId }: AvatarProps) {
  const { userId: ctxUserId, forename, surname, email, loading } = useAuth();
  const { wilmaUsers } = useWilmaUsers();
  const [foundUser, setFoundUser] = useState<Partial<IWilmaUser>>();

  useEffect(() => {
    if (!loading && !wilmaUsers.isLoading && wilmaUsers.data) {
      if (userId) {
        setFoundUser(wilmaUsers.data.data.find((u) => u.id === userId)!);
      } else {
        setFoundUser({
          id: ctxUserId!,
          forename: forename!,
          surname: surname!,
          email: email!,
        });
      }
    }
  }, [wilmaUsers.isLoading, wilmaUsers.data, loading, userId]);

  if (!foundUser) {
    return (
      <div className="h-9 w-9 bg-gray-200 rounded-full animate-pulse"></div>
    );
  } else {
    return (
      <div className={`${classes} aspect-square`}>
        <span
          style={{ background: stringToColour(foundUser!.id) }}
          className="w-full h-full inline-flex items-center justify-center rounded-full"
        >
          <span className="text-lg leading-none text-white">
            {`${foundUser.forename} ${foundUser.surname}`
              .split(" ")
              .filter((w) => RegExp(/^\p{L}/, "u").test(w))
              .map((w, i) => (i < 2 ? w.substring(0, 1).toUpperCase() : ""))}
          </span>
        </span>
      </div>
    );
  }
}
