import { useEffect, useState } from "react";
import classNames from "../../utils/classNames";
import { IPostcodePrice } from './../../models/PostcodeGroup';
import usePostcodePriceMutations from "../../data/usePostcodePriceMutations";
import useDefaultCRUDHandlers from './../../hooks/useDefaultCRUDHandlers';


export default function EditPostcodeAreaPrice({ postcodeArea }: { postcodeArea: IPostcodePrice }) {
  const { update } = usePostcodePriceMutations();
  const { saveHandlers, deleteHandlers } = useDefaultCRUDHandlers("Postcode Area");
  const [price, setPrice] = useState(postcodeArea.price);

  useEffect(() => {
    setPrice(postcodeArea.price);
  }, [postcodeArea.price]);

  const handlePriceChange = (e: React.FocusEvent<HTMLInputElement>) => {
    if (postcodeArea.price === parseFloat(e.target.value)) return;

    update.mutate({
      ...postcodeArea,
      price: parseFloat(e.target.value)
    }, {
      ...saveHandlers
    });
  }


  return (
    <div className={classNames("sm:col-span-1")}>
      <input
        id={postcodeArea.id}
        value={price}
        onChange={(e) => setPrice(parseFloat(e.target.value))}
        type="number"
        step="0.01"
        onBlur={handlePriceChange}
        className="w-full mt-1 text-sm text-gray-900 border-1 border-gray-400"
      />
    </div>
  )
}