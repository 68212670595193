
import classNames from "../../utils/classNames";
import { IPostcodePrice } from '../../models/PostcodeGroup';
import usePostcodePriceMutations from "../../data/usePostcodePriceMutations";
import useDefaultCRUDHandlers from '../../hooks/useDefaultCRUDHandlers';


export default function RemovePostcodeAreaPrice({ postcodeArea }: { postcodeArea: IPostcodePrice }) {
  const { remove } = usePostcodePriceMutations();
  const { deleteHandlers } = useDefaultCRUDHandlers("Postcode Area");

  const handlePriceChange = () => {
    remove.mutate(postcodeArea.id, {
      ...deleteHandlers
    });
  }


  return (
    <div className={classNames("sm:col-span-1")}>
      <button
        type="button"
        onClick={handlePriceChange}
        className="rounded-md border border-transparent bg-red-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-red-700 disabled:bg-gray-500 disabled:hover:bg-gray-500"
      >
        Remove
      </button>
    </div>
  )
}