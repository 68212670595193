import classNames from "../../../utils/classNames";

export interface PillTabProps {
  label: string;
  active: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

export default function PillTab({ label, active, onClick }: PillTabProps) {
  return (
    <div
      className={classNames(
        active
          ? "bg-gp-blue-100 text-gp-blue-700"
          : "text-gray-500 hover:text-gray-700",
        "px-3 py-2 font-medium text-sm rounded-md hover:cursor-pointer"
      )}
      onClick={onClick}
    >
      {label}
    </div>
  );
}
