import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { CreatePostcodePriceRequest, IPostcodeGroup, IPostcodeGroupsResponse, IPostcodePrice } from "../models/PostcodeGroup";

export default function usePostcodePriceMutations() {
  const { get, post, put, patch, del } = useApiHelper();
  const queryClient = useQueryClient();


  const create = useMutation(
    (postcodePrice: CreatePostcodePriceRequest) => post("/postcodePrice", postcodePrice),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["postcodeGroups"]);
      },
    }
  );

  const update = useMutation(
    (postcodePrice: IPostcodePrice) => patch(`/postcodePrice`, postcodePrice),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["postcodeGroups"]);
      },
    }
  );

  const remove = useMutation(
    (postcodeId: string) => del(`/postcodePrice/${postcodeId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["postcodeGroups"]);
      },
    }
  );

  return {
    // postcodeGroups,
    create,
    update,
    remove,
  };
}
