import { CameraIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import useVehicles from "../../data/useVehicles";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import Modal from "../../layouts/Modal";
import { IVehicle } from "../../models/Vehicle";
import Card from "../shared/Card/Card";
import useVehiclesMutations from "../../data/useVehiclesMutations";

export default function VehicleDetails({ vehicle, driver = false }: { vehicle: IVehicle, driver?: boolean }) {
  const { vrmRefresh } = useVehiclesMutations();
  const { saveHandlers } = useDefaultCRUDHandlers("Vehicle");
  const [open, setOpen] = useState(false);
  const [vrm, setVrm] = useState(vehicle.vrm);

  useEffect(() => {

  }, [vehicle])

  const handleUpdateVrm = () => {
    //Uppercase and remove whitespace from vrm
    const updatedVehicle = {
      ...vehicle,
      vrm: vrm.toUpperCase().replace(/\s/g, "")
    };

    vrmRefresh.mutate(updatedVehicle, {
      onSuccess: () => {
        setOpen(false);
        saveHandlers.onSuccess();
      },
      onError: () => {
        saveHandlers.onError();
      }
    })
  }

  return (
    <>
      <Modal open={open} setOpen={setOpen} width="max-w-xl">
        <label>Enter VRM to search for:</label>
        <input defaultValue={vehicle.vrm} onChange={(e) => setVrm(e.target.value)} className="my-5 w-full text-sm" type="text" />
        <button
          onClick={() => handleUpdateVrm()}
          className="float-right rounded-md border border-transparent bg-gp-blue-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gp-blue-700">
          Save
        </button>
      </Modal>
      <Card title="Vehicle Details" bodyClassName="p-4">
        <dl className="divide-y divide-gray-200">
          {!vehicle.found && (
            <div className="mb-4">
              <p className="text-red-400 font-medium text-xl text-center">VEHICLE NOT FOUND</p>

              <div className="flex justify-center w-full mt-2">
                <button
                  onClick={() => { setOpen(true) }}
                  className="rounded-md border border-transparent bg-gp-blue-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gp-blue-700"
                >
                  Find Vehicle
                </button>
              </div>
            </div>
          )}

          <div className="py-2 grid grid-cols-3 gap-4">
            <dt className="text-sm font-medium text-gray-500">Make/Model</dt>
            <dd className="text-sm text-gray-900 col-span-2">
              {vehicle.combinedMake} {vehicle.combinedModel}
            </dd>
          </div>
          <div className="py-2 grid grid-cols-3 gap-4">
            <dt className="text-sm font-medium text-gray-500">VRM</dt>
            <dd className="flex justify-between items-center text-sm text-gray-900 col-span-2">
              <div>{vehicle.vrm}</div>
              {!driver && (
                <button
                  onClick={() => setOpen(true)}
                  className="inline-flex items-center rounded-md border border-transparent bg-gp-blue-600 px-1 py-1 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gp-blue-700">
                  Update
                </button>
              )}
            </dd>
          </div>
          <div className="py-2 grid grid-cols-3 gap-4">
            <dt className="text-sm font-medium text-gray-500">Make</dt>
            <dd className="text-sm text-gray-900 col-span-2">
              {vehicle.combinedMake}
            </dd>
          </div>
          <div className="py-2 grid grid-cols-3 gap-4">
            <dt className="text-sm font-medium text-gray-500">Model</dt>
            <dd className="text-sm text-gray-900 col-span-2">
              {vehicle.combinedModel}
            </dd>
          </div>
          <div className="py-2 grid grid-cols-3 gap-4">
            <dt className="text-sm font-medium text-gray-500">Year</dt>
            <dd className="text-sm text-gray-900 col-span-2">
              {vehicle.dvlaYearOfManufacture}
            </dd>
          </div>
          {!driver && (
            <>
              <div className="py-2 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500">Engine Size</dt>
                <dd className="text-sm text-gray-900 col-span-2">
                  {vehicle.combinedEngineCapacity}cc
                </dd>
              </div>
              <div className="py-2 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500">Fuel Type</dt>
                <dd className="text-sm text-gray-900 col-span-2">
                  {vehicle.combinedFuelType}
                </dd>
              </div>
              <div className="py-2 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500">Transmission</dt>
                <dd className="text-sm text-gray-900 col-span-2">
                  {vehicle.combinedForwardGears} Speed {vehicle.combinedTransmission}
                </dd>
              </div>
              <div className="py-2 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500">Engine Code</dt>
                <dd className="text-sm text-gray-900 col-span-2">
                  {vehicle.engineModelCode}
                </dd>
              </div>
              <div className="py-2 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500">Chassis No</dt>
                <dd className="text-sm text-gray-900 col-span-2">
                  {vehicle.combinedVIN}
                </dd>
              </div>
              <div className="py-2 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500">Kerb Weight</dt>
                <dd className="text-sm text-gray-900 col-span-2">
                  {vehicle.kerbWeightMin ? (
                    <span>{vehicle.kerbWeightMin} {vehicle.kerbWeightMax && ` - ${vehicle.kerbWeightMax}`} kg</span>
                  ) : (
                    "Unavailable"
                  )}
                </dd>
              </div>
            </>
          )}

          <div className="py-4 flex items-center justify-center bg-white rounded-lg">
            {vehicle.vehicleImageUrl ? (
              <img
                className="w-full"
                src={`${vehicle.vehicleImageUrl}`}
              />
            ) : (
              <CameraIcon className="w-1/2 text-gray-600" />
            )}
          </div>

        </dl>
      </Card>
    </>
  );
}
