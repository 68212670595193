import { useEffect, useState } from "react";
import { usePageNameContext } from "../../contexts/PageTitleContext";

import LoadingWheel from "../../components/shared/LoadingWheel";
import { PlusSmallIcon } from "@heroicons/react/24/outline";
import usePostcodeGroups from "../../data/usePostcodeGroups";
import { IPostcodeGroup } from "../../models/PostcodeGroup";
import PostcodesGroupTable from "../../components/postcodeGroups/PostcodeGroupTable";
import PostcodeGroupsModal from "../../components/postcodeGroups/PostcodeGroupModal";

export default function ScrapPrice() {
  const { setInfo } = usePageNameContext();
  // const { scrapPrices: allScrapPrices, create, update } = useScrapPrices();
  const { postcodeGroups: allPostcodeGroups, create, update } = usePostcodeGroups();
  const [postcodeGroups, setPostcodeGroups] = useState<IPostcodeGroup[] | undefined>(undefined);
  const [group, setGroup] = useState<IPostcodeGroup | undefined>(undefined);
  const [editGroup, setEditGroup] = useState<IPostcodeGroup | undefined>(undefined);

  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [createOpen, setCreateOpen] = useState(false);

  useEffect(() => {
    setInfo({
      name: "Scrap Prices",
      desc: "Catalogue scrap prices against outward postcodes",
    });
  }, []);

  useEffect(() => {
    if (!allPostcodeGroups.isLoading && allPostcodeGroups.data) {

      // Filtering code goes here

      setPostcodeGroups(allPostcodeGroups.data?.data);
      setIsLoading(false);
    }
  }, [allPostcodeGroups.isLoading, allPostcodeGroups.data, searchTerm]);

  const handleSetPostcodeGroupPrice = (postcodeGroup: IPostcodeGroup) => {
    setEditGroup(postcodeGroup);
    setCreateOpen(true);
  };

  const handleAddPostcodeGroup = () => {
    setEditGroup(undefined);
    setCreateOpen(true);
  }

  return allPostcodeGroups.isLoading || isLoading ? (
    <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
      <LoadingWheel />
    </div>
  ) : (
    <>
      <PostcodeGroupsModal open={createOpen} setOpen={setCreateOpen} postcodeGroup={editGroup} />
      <div className="mt-4 flex justify-between">

        <button
          className=" bg-primary-600 hover:bg-primary-700 inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm"
          type="button"
          onClick={() => handleAddPostcodeGroup()}
        >
          <PlusSmallIcon className="h-5 w-5 mr-2" />
          Add New Postcode Group
        </button>

      </div>

      {/* Table of postcode groups */}
      <PostcodesGroupTable postcodeGroups={postcodeGroups!} setPostcodeGroupPrice={handleSetPostcodeGroupPrice} />
    </>
  );
}
