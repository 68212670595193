import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { NotifierContextProvider } from "react-headless-notifier";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import ReactDOM from "react-dom/client";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import Lead from "./pages/Leads/Lead";
import Leads from "./pages/Leads/LeadList";
import AdminMenu from "./pages/Admin/AdminMenu";
import CreateLead from "./pages/Leads/CreateLead";
import Dashboard from "./pages/Dashboard/Dashboard";
import ErrorBoundry from "./pages/Error/ErrorBoundry";
import { AuthProvider } from "./contexts/AuthContext";
import DashboardLayout from "./layouts/DashboardLayout";
import { PageNameProvider } from "./contexts/PageTitleContext";
import CustomerWebForm from "./pages/Public Pages/CustomerWebForm";

import "./index.css";
import RequireAuth from "./pages/Login/RequireAuth";
import Settings from "./pages/Settings/Settings";
import ScrapPrice from './pages/ScrapPrice/ScrapPrice';
import PriceSettings from "./pages/PriceSettings/PriceSettings";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter(
  [
    {
      path: "/customer-info",
      element: <CustomerWebForm />,
      errorElement: <ErrorBoundry />,
    },
    {
      path: "/",
      element: <DashboardLayout />,
      errorElement: <ErrorBoundry />,
      children: [
        {
          index: true,
          element: <Leads />,
        },
        {
          //Admin only routes
          element: <RequireAuth allowedRoles={{ isAdmin: true, isStaff: false, isDriver: false }} />,
          children: [
            {
              path: "admin",
              element: <AdminMenu />,
              children: [
                {
                  index: true,
                  element: <AdminMenu />,
                },
                {
                  path: ":section",
                  element: <AdminMenu />
                },
              ]
            },
          ]
        },
        {
          //Staff routes
          element: <RequireAuth allowedRoles={{ isAdmin: false, isStaff: true, isDriver: false }} />,
          children: [
            {
              path: "leads",
              children: [
                {
                  index: true,
                  element: <Leads />,
                },
                {
                  path: ":leadId",
                  element: <Lead />,
                  children: [
                    {
                      path: ":tab",
                      element: <Lead />,
                    }
                  ]
                },
                {
                  path: "create",
                  element: <CreateLead />,
                }
              ]
            },
            {
              path: "scrap-price",
              element: <ScrapPrice />,
              children: [
              ],
            },
            {
              path: "price-settings",
              element: <PriceSettings />,
              children: [
              ],
            },
            {
              path: "settings",
              element: <Settings />,
              children: [
              ],
            },
          ]
        },
        {
          //Driver routes
          element: <RequireAuth allowedRoles={{ isAdmin: false, isStaff: false, isDriver: true }} />,
          children: [
          ]
        }
      ],
    },
  ], {
  basename: process.env.PUBLIC_URL,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
    },
  },
});

root.render(
  <AuthProvider>
    <PageNameProvider>
      <QueryClientProvider client={queryClient}>
        <NotifierContextProvider
          config={{
            max: 5,
            position: "topRight",
          }}
        >
          <DndProvider backend={HTML5Backend}>
            <RouterProvider router={router} />
            <ReactQueryDevtools initialIsOpen={false} />
          </DndProvider>
        </NotifierContextProvider>
      </QueryClientProvider>
    </PageNameProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
