import { PaymentStatus } from "../models/Lead";

export default function PaymentBubble(status: PaymentStatus) {

  var className = "rounded-full px-3 py-1 text-sm font-semibold ";
  switch (status) {
    case PaymentStatus["Not Started"]:
      className += "bg-yellow-100 text-yellow-800";
      break;
    case PaymentStatus.Raised:
      className += "bg-blue-100 text-blue-600";
      break;
    case PaymentStatus.Approved:
      className += "bg-purple-100 text-purple-800";
      break;
    case PaymentStatus.Sent:
      className += "bg-green-100 text-green-800";
      break;
    case PaymentStatus.Failed:
      className += "bg-red-100 text-red-800";
      break;
    default:
      className += "bg-yellow-100 text-yellow-800";
      break;
  }

  return <span className={className}>{PaymentStatus[status]}</span>
};