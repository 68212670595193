import { useEffect, useState } from "react";
import { IPostcodeGroup } from "../../models/PostcodeGroup";
import ConfirmModal from "../shared/Confirm/ConfirmModal";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import { NumberInput } from "../shared/Inputs/NumberInput";
import { TextInput } from "../shared/Inputs/TextInput";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import usePostcodeGroups from "../../data/usePostcodeGroups";
import { useForm } from "react-hook-form";

type FormValues = {
  name: string,
  postcode: string,
  basePrice: number,
};

export default function AddEditPostcodeGroup({ postcodeGroup, setOpen }: { postcodeGroup?: IPostcodeGroup | undefined, setOpen: any }) {
  const { deleteHandlers, saveHandlers } = useDefaultCRUDHandlers("Postcode Group");
  const { update, create, remove } = usePostcodeGroups();
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const { register, handleSubmit, reset } = useForm<FormValues>();

  const onSubmit = (data: any) => {

    const updatedScrapPrice = {
      ...postcodeGroup,
      ...data
    };

    if (postcodeGroup !== undefined) {
      update.mutate(updatedScrapPrice, {
        onSuccess: (data) => {
          saveHandlers.onSuccess();
          setOpen(false);
          reset({});
        },
        onError: saveHandlers.onError
      });
    } else {
      create.mutate(updatedScrapPrice, {
        onSuccess: (data) => {
          saveHandlers.onSuccess();
          setOpen(false);
          reset({});
        },
        onError: saveHandlers.onError
      });
    }
  }

  const handleDelete = () => {
    remove.mutate(postcodeGroup?.id!, {
      onSuccess: (data) => {
        deleteHandlers.onSuccess();
        setOpen(false);
        setDeleteOpen(false);
        reset({});
      },
      onError: deleteHandlers.onError
    });
  }

  useEffect(() => {

    if (postcodeGroup !== undefined) {
      reset({ ...postcodeGroup });
    } else {
      reset({})
    }
  }, [postcodeGroup, reset]);

  return (
    <>
      <ConfirmModal open={deleteOpen} setOpen={setDeleteOpen}
        title={"Delete Postcode Group"} message={`Are you sure you want to delete this postcode group entry?\n This action cannot be undone.`}
        confirmButtonText={"Delete"} onConfirm={handleDelete} isLoading={false}
      />

      <h1 className="text-xl">{`${postcodeGroup ? "Edit" : "Add"} a Postcode Group`}</h1>
      <div className="mt-4">
        <form className="space-y-4 border-t border-gray-200" onSubmit={handleSubmit(onSubmit)} >
          <div className="pt-4 grid grid-cols-1 gap-x-4 gap-y-4 sm:gap-y-6 sm:grid-cols-1">

            <TextInput label="Name" registerName="name" register={register} />

            <div className="grid grid-cols-2 gap-x-4 gap-y-4">
              <TextInput disabled={!!postcodeGroup} label="Postcode" registerName="postcode" register={register} />
              <NumberInput label="Base Price per Tonne (£)" registerName="basePrice" register={register} />
            </div>

            <ButtonInput label={`${postcodeGroup ? "Save Changes" : "Create"}`} isSubmit={true} onClick={undefined} classes={undefined} />
            {postcodeGroup !== undefined && (
              <ButtonInput label={"Delete"} isSubmit={false} onClick={() => (setDeleteOpen(true))} classes={"!bg-red-500 hover:!bg-red-600"} />
            )}
          </div>
        </form>
      </div>
    </>
  )

}