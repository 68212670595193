import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IAllLeadsResponse, ILead } from "../models/Lead";

export default function useCustomer() {
  const { customerPostFormData, customerPatch } = useApiHelper();
  const queryClient = useQueryClient();

  const uploadDocs = useMutation(
    (body: any) => customerPostFormData(`/customer/documents/${body.leadId}`, body),
    {
      onSuccess: () => {
      },
    }
  );

  const updateLead = useMutation(
    (body: any) => customerPatch(`/customer/lead/${body.leadId}`, body),
    {
      onSuccess: () => {
      },
    }
  );

  return {
    uploadDocs,
    updateLead,
  };
}
