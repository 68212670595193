import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import usePriceSettings from "../../data/usePriceSettings";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { ICustomerPriceConfiguration } from "../../models/PriceSettings";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import SwitchInput from "../shared/Inputs/SwitchInput";
import GeneralPriceSettingSingle from "./PriceConfiguration/GeneralPriceSettingSingle";
import LoadingWheel from "../shared/LoadingWheel";


export default function GeneralPriceSettings() {
  const { priceSettings, updateConfig } = usePriceSettings();
  const { saveHandlers } = useDefaultCRUDHandlers("Price Settings");
  const [isLoading, setIsLoading] = useState(true);
  const { register, handleSubmit, watch, setValue, reset } = useForm<ICustomerPriceConfiguration>({
    defaultValues: {
      id: "",
      doesCollection: false,
      doesDropOff: false,
      collectionPriceSetting: { value: 0, isFixed: true },
      dropOffPriceSetting: { value: 0, isFixed: true },
      alloyWheelsPriceSetting: { value: 0, isFixed: true },
      noFaultPriceSetting: { value: 0, isFixed: true },
      catalyticConverterPriceSetting: { value: 0, isFixed: true }
    }
  });

  const onSubmit = (data: ICustomerPriceConfiguration) => {
    updateConfig.mutate(data, saveHandlers);
  };

  useEffect(() => {
    if (!priceSettings.isLoading) {
      if (priceSettings.data?.data.customerPriceConfiguration) reset(priceSettings.data?.data.customerPriceConfiguration);
      setIsLoading(false);
    }
  }, [priceSettings.data, priceSettings.isLoading])

  if (isLoading) return <LoadingWheel />

  return (
    <div className="bg-white shadow rounded p-4">

      <h1 className="text-xl font-semibold">General</h1>
      <hr />

      <form className="py-4 gap-4 grid grid-cols-2" onSubmit={handleSubmit(onSubmit)}>

        <SwitchInput label={"Do you collect?"} registerName={"doesCollection"} register={register} setValue={setValue} watch={watch} />
        <SwitchInput label={"Do you allow drop offs?"} registerName={"doesDropOff"} register={register} setValue={setValue} watch={watch} />

        <h1 className="col-span-2 mt-4 text-lg font-semibold">How much extra value do these add?</h1>

        <GeneralPriceSettingSingle label={"Collection"} objectName={"collectionPriceSetting"} register={register} setValue={setValue} watch={watch} />
        <GeneralPriceSettingSingle label={"Drop Off"} objectName={"dropOffPriceSetting"} register={register} setValue={setValue} watch={watch} />
        <GeneralPriceSettingSingle label={"Alloy Wheels"} objectName={"alloyWheelsPriceSetting"} register={register} setValue={setValue} watch={watch} />
        <GeneralPriceSettingSingle label={"No Fault"} objectName={"noFaultPriceSetting"} register={register} setValue={setValue} watch={watch} />
        <GeneralPriceSettingSingle label={"Catalytic Converter"} objectName={"catalyticConverterPriceSetting"} register={register} setValue={setValue} watch={watch} />

        <div> {/* Spacer */} </div>

        <div className="flex items-center pt-7 w-full">
          <ButtonInput label="Save" isSubmit />
        </div>

      </form>
    </div>
  )
}