import { BaseEntity, DefaultResponseWithData } from "./system";

export interface ISingleContactRespose
  extends DefaultResponseWithData<IContact> { }
export interface IAllContactsResponse
  extends DefaultResponseWithData<IContact[]> { }

export enum ContactType {
  Customer,
  Supplier,
  Staff,
  Driver,
  Admin,
  Mechanic
}

export interface IContact extends BaseEntity {
  contactType: ContactType;
  fullname: string;
  telephone1: string;
  telephone2: string;
  email: string;
  line1: string;
  line2: string;
  town: string;
  county: string;
  postcode: string;
  notes: string;
  version: string | null;
  foregroundLocation: boolean;
  backgroundLocation: boolean;
  mapColour: string;
  pin: string;
}
