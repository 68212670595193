export interface PillTabMenuProps {
  children: React.ReactNode;
  ariaLabel?: string;
  className?: string;
}

export default function PillTabMenu({
  children,
  ariaLabel,
  className,
}: PillTabMenuProps) {
  return (
    <nav
      className={`mt-4 flex space-x-4 ${className && className}`}
      aria-label={ariaLabel ?? "Tabs"}
    >
      {children}
    </nav>
  );
}
