import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IAllPriceSettingsResponse, ICustomerGenericPriceSetting, ICustomerPriceConfiguration } from "../models/PriceSettings";

export default function usePriceSettingsMutations() {
  const { get, post, put, del } = useApiHelper();
  const queryClient = useQueryClient();

  // const priceSettings = useQuery({
  //   queryKey: ["priceSettings"],
  //   queryFn: () => get<IAllPriceSettingsResponse>("/priceSetting"),
  //   refetchInterval: 1000 * 10,
  // });

  // const create = useMutation(
  //   (scrapPrice: IScrapPrice) => post("/scrapprice", scrapPrice),
  //   {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries(["scrapPrices"]);
  //     },
  //   }
  // );

  const updateConfig = useMutation(
    (config: ICustomerPriceConfiguration) => put(`/priceSetting/configuration/${config.id}`, config),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["priceSettings"]);
      },
    }
  );

  const createSpecific = useMutation(
    (config: ICustomerGenericPriceSetting) => post(`/priceSetting/generic`, config),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["priceSettings"]);
      },
    }
  );

  const updateSpecific = useMutation(
    (config: ICustomerGenericPriceSetting) => put(`/priceSetting/generic/${config.id}`, config),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["priceSettings"]);
      },
    }
  );

  const removeSpecific = useMutation(
    (configId: string) => del(`/priceSetting/generic/${configId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["priceSettings"]);
      },
    }
  );


  // const remove = useMutation(
  //   (scrapPriceId: string) => del(`/scrapprice/${scrapPriceId}`),
  //   {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries(["scrapPrices"]);
  //     },
  //   }
  // );

  return {
    // priceSettings,
    updateConfig,
    createSpecific,
    updateSpecific,
    removeSpecific,
  };
}
