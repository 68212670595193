import { useEffect, useState } from "react";

import { DateTime } from "luxon";

import { useQueryClient } from "@tanstack/react-query";

import useCod from "../../../data/useCod";
import useEmails from "../../../data/useEmails";
import useSingleLead from "../../../data/useSingleLead";
import useVehiclesMutations from "../../../data/useVehiclesMutations";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";
import Modal from "../../../layouts/Modal";
import { ILead, LeadSource, LeadStatus } from "../../../models/Lead";
import { IVehicle } from "../../../models/Vehicle";
import formatDateTime from "../../../utils/formatDateTime";
import useNotification from "../../notifications/useNotifications";
import Card from "../../shared/Card/Card";
import ConfirmModal from "../../shared/Confirm/ConfirmModal";
import { ButtonInput } from "../../shared/Inputs/ButtonInput";

export default function LeadStatusCard({ leadId }: { leadId: string }) {
  const { lead, update: updateLead, complete, downloadDoc, getCollectionNote } = useSingleLead(leadId)
  const { update: updateVehicle } = useVehiclesMutations();
  const queryClient = useQueryClient();
  const { runCod } = useCod();
  const { sendDocumentsEmail } = useEmails();
  const { addNotification } = useNotification();
  const { saveHandlers } = useDefaultCRUDHandlers("Lead");
  // const [lead, setLead] = useState<ILead>(singleLead.data!.data!);
  const [refresh, setRefresh] = useState(0);
  const [collectionOnOpen, setCollectionOnOpen] = useState(false);
  const [collectedOpen, setCollectedOpen] = useState(false);
  const [confirmCodOpen, setConfirmCodOpen] = useState(false);
  const [confirmComplete, setConfirmComplete] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [completeLoading, setCompleteLoading] = useState(false);
  const [collectionOn, setCollectionOn] = useState<string | null>(null);
  const [collectionOnSlot, setCollectionOnSlot] = useState<number>(0);
  const [collectedOn, setCollectedOn] = useState<string | null>(null);
  const [leadStatus, setLeadStatus] = useState(LeadStatus["Awaiting Contact"]);

  const handleSaveCollectionOn = () => {
    const updatedVehicle: IVehicle = {
      ...lead.data?.data.vehicle!,
      scheduledCollectionOn: collectionOn,
      scheduledCollectionSlot: collectionOnSlot
    };

    setLeadStatus(LeadStatus["Scheduled"]);
    handleUpdateVehicle(updatedVehicle);
  }

  const handleSaveCollectedOn = () => {
    const collected = lead.data?.data.vehicle?.scheduledCollectionOn ? DateTime.fromISO(lead.data?.data.vehicle?.scheduledCollectionOn) : DateTime.now();

    setCollectedOn(collected.toISODate());
    setLeadStatus(LeadStatus["Collected"]);

    const updatedVehicle: IVehicle = {
      ...lead.data?.data.vehicle!,
      collectedOn: collected.toISODate()
    };

    handleUpdateVehicle(updatedVehicle);
  }

  const handleClearCollectionOn = () => {
    const updatedVehicle: IVehicle = {
      ...lead.data?.data.vehicle!,
      scheduledCollectionOn: null,
      scheduledCollectionSlot: 0,
      collectionStart: null,
      collectionEnd: null,
    };

    const updatedLead: ILead = {
      ...lead.data?.data!,
      vehicle: updatedVehicle,
      status: LeadStatus["Awaiting Contact"]
    }

    setLeadStatus(LeadStatus["Awaiting Contact"]);

    setCollectionOn(null);
    setCollectionOnSlot(0);

    updateLead.mutate(updatedLead, {
      onSuccess: () => {
        handleUpdateVehicle(updatedVehicle);
      },
      onError: () => {
        saveHandlers.onError();
      }
    });
  }

  const handleClearCollectedOn = () => {
    const updatedVehicle: IVehicle = {
      ...lead.data?.data.vehicle!,
      collectedOn: null
    };

    setCollectedOn("");

    if (updatedVehicle.scheduledCollectionOn) {
      setLeadStatus(LeadStatus["Scheduled"]);
    } else {
      setLeadStatus(LeadStatus["Awaiting Contact"]);
    }

    handleUpdateVehicle(updatedVehicle);
  }

  const handleUpdateVehicle = async (vehicle: IVehicle) => {
    await updateVehicle.mutateAsync(vehicle, {
      onSuccess: () => {
        saveHandlers.onSuccess();
        setCollectionOnOpen(false);
        setCollectedOpen(false);

        //Need to wait for the query to update before invalidating lead - somehow the best way to make this work
        setTimeout(() => {
          queryClient.invalidateQueries(["lead", leadId]);
        }, 1000 * 2);
      },
      onError: () => {
        saveHandlers.onError();
      }
    })
  }

  const handleStatusChange = (status: string) => {
    const updatedLead = {
      ...lead.data?.data!,
      status: parseInt(status)
    };

    setLeadStatus(parseInt(status));

    //If changing to collected then also update the collected on date
    if (parseInt(status) === LeadStatus["Collected"]) {
      updateLead.mutate(updatedLead, {
        onSuccess: () => {
          //Only changes collectedOn if it's null
          if (!lead.data?.data.vehicle?.collectedOn) {
            handleSaveCollectedOn();
          }
          saveHandlers.onSuccess();
        },
        onError: () => {
          saveHandlers.onError();
        }
      });
    } else {
      updateLead.mutate(updatedLead, saveHandlers);
    }
  }

  const handleCodRequired = () => {
    let updatedVehicle = lead.data?.data.vehicle!;

    updatedVehicle.codRequired = !updatedVehicle.codRequired;

    updateVehicle.mutate(updatedVehicle, saveHandlers);
  }

  const handleCodConfirmed = (confirm: boolean) => {
    let updatedVehicle = lead.data?.data.vehicle!;

    if (confirm) {
      updatedVehicle.codConfirmedOn = DateTime.now().toISO();
    } else {
      updatedVehicle.codConfirmedOn = null;
    }

    updateVehicle.mutate(updatedVehicle, saveHandlers);
  }

  const handleGenerateCod = () => {
    setisLoading(true);

    if (!lead.data?.data.vehicle?.line1 || !lead.data?.data.vehicle?.postcode || !lead.data?.data.vehicle?.town) {
      addNotification({
        variant: "error",
        primaryText: "Cannot generate COD certificate. Please ensure the vehicle address is has at least Address Line 1, Postcode and Town."
      });
      setisLoading(false);
      return;
    }

    runCod.mutate(lead.data?.data.id, {
      onSuccess: () => {
        setConfirmCodOpen(false);
        setisLoading(false);
        saveHandlers.onSuccess();
      },
      onError: (error: any) => {
        //saveHandlers.onError();
        addNotification({
          variant: "error",
          primaryText: error.message,
        });
        setisLoading(false);
      }
    });
  }

  const downloadCodCertificate = () => {
    var ids = {
      leadId: lead.data?.data.id,
      docId: lead.data?.data.vehicle?.codCertificateDocument
    }

    downloadDoc.mutate(ids, {
      onSuccess: (file: any) => {
        const url = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.type = 'image/png';
        link.download = `${lead.data?.data.vehicle?.vrm} COD Certificate.png`;
        link.click();
        URL.revokeObjectURL(url);
      }
    })
  }

  const sendDocuments = (collectionNote: boolean, codCertificate: boolean) => {
    let body = {
      leadId: lead.data?.data.id,
      collectionNote: collectionNote,
      codCertificate: codCertificate
    }

    sendDocumentsEmail.mutate(body, {
      onSuccess: () => {
        addNotification({
          variant: "success",
          primaryText: "Documents sent",
        });
      },
      onError: () => {
        addNotification({
          variant: "error",
          primaryText: "Unable to send documents",
        });
      }
    });
  }

  const printCollectionNote = () => {
    getCollectionNote.mutate(lead.data?.data.id!, {
      onSuccess: (file: any) => {
        //print file
        const url = window.URL.createObjectURL(file);
        const w = window.open(url, "_blank");
        w?.print();
      },
      onError: () => {
        addNotification({
          variant: "error",
          primaryText: "Unable to print collection note",
        });
      }
    })
  }

  const handleComplete = () => {
    setCompleteLoading(true);

    complete.mutate(lead.data?.data.id!, {
      onSuccess: () => {
        setConfirmComplete(false);
        saveHandlers.onSuccess();
      },
      onError: (res: any) => {
        addNotification({
          variant: "error",
          primaryText: res.message,
        })
      },
      onSettled: () => {
        setCompleteLoading(false);
      }
    })
  }

  useEffect(() => {
    if (lead.isLoading || !lead.data?.data) return;

    // console.log(lead.data.data)
    setLeadStatus(lead.data.data.status);
    setCollectionOn(lead.data.data.vehicle?.scheduledCollectionOn ?? "");
    setCollectionOnSlot(lead.data.data.vehicle?.scheduledCollectionSlot ?? 0);
    setCollectedOn(lead.data.data.vehicle?.collectedOn ?? "");
    setLeadStatus(lead.data.data.status);

  }, [lead.isLoading, lead.data?.data])

  return (
    <>
      <ConfirmModal open={confirmComplete} setOpen={setConfirmComplete} onConfirm={() => handleComplete()}
        title="Complete Lead" message={`Really complete this lead?\nThis will update the status to 'Complete' and send the customer a confirmation email.`}
        confirmButtonText="Complete" isLoading={completeLoading}
      />
      <Modal open={collectionOnOpen} setOpen={setCollectionOnOpen} width="sm:max-w-4xl">
        <div className="flex justify-between">
          <label>Set Collection Scheduled On:</label>

          <button
            onClick={() => handleClearCollectionOn()}
            className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gp-blue-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
          >
            Clear
          </button>
        </div>

      </Modal>
      <ConfirmModal open={confirmCodOpen} setOpen={setConfirmCodOpen} title="Confirm COD" confirmButtonText="Generate COD"
        message="Are you sure you want to create a certificate of destruction for this vehicle? This process is irreversible"
        onConfirm={handleGenerateCod} isLoading={isLoading}
      />
      <Card
        bodyClassName="p-4"
        title="Lead Status"
      >
        <div className="md:grid md:grid-cols-5 gap-3">

          <div className="col-span-5 divide-y ">

            <div className="py-2 grid grid-cols-2 gap-4">
              <dt className="text-sm font-medium text-gray-500">
                Lead Source
              </dt>
              <dd className="items-center text-sm text-gray-900">
                <div>
                  {LeadSource[lead.data?.data.website!]}
                </div>
              </dd>
            </div>
            <div className="py-2 grid grid-cols-2 gap-4">
              <dt className="text-sm font-medium text-gray-500">
                Collection Scheduled On
              </dt>
              <dd className="flex justify-between items-center text-sm text-gray-900">
                <div>
                  {collectionOn ? formatDateTime(collectionOn, DateTime.DATE_SHORT) : "Not Set"}
                </div>
                <button
                  type="button"
                  onClick={() => setCollectionOnOpen(true)}
                  className="ml-3 inline-flex items-center rounded-md border border-transparent bg-gp-blue-600 px-1 py-1 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gp-blue-700">
                  Change
                </button>
              </dd>
            </div>
            <div className="py-2 grid grid-cols-2 gap-4">
              <dt className="text-sm font-medium text-gray-500">
                Collected On
              </dt>
              <dd className="flex justify-between items-center text-sm text-gray-900">
                <div>
                  {collectedOn ? formatDateTime(collectedOn, DateTime.DATE_SHORT) : "Not Set"}
                </div>
                {collectedOn ? (
                  <button
                    type="button"
                    onClick={() => handleClearCollectedOn()}
                    className="ml-3 inline-flex items-center rounded-md border border-transparent bg-gp-blue-600 px-1 py-1 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gp-blue-700">
                    Clear
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => handleSaveCollectedOn()}
                    className="ml-3 inline-flex items-center rounded-md border border-transparent bg-gp-blue-600 px-1 py-1 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gp-blue-700">
                    Set as collected
                  </button>
                )}

              </dd>
            </div>
            <div className="py-2 grid grid-cols-2 gap-4">
              <dt className="text-sm font-medium text-gray-500">
                COD Required
              </dt>
              <dd className="flex justify-between items-center text-sm text-gray-900">
                <div>
                  {lead.data?.data.vehicle?.codRequired ? "Yes" : "No"}
                </div>
                {!lead.data?.data.vehicle?.codCertificateNumber && (
                  <button
                    type="button"
                    onClick={handleCodRequired}
                    className="ml-3 inline-flex items-center rounded-md border border-transparent bg-gp-blue-600 px-1 py-1 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gp-blue-700">
                    Toggle
                  </button>
                )}
              </dd>
            </div>

            {(lead.data?.data.vehicle?.codRequired || lead.data?.data.vehicle?.codCertificateNumber) && (
              <>
                <div className="py-2 grid grid-cols-2 gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    COD Confirmed
                  </dt>
                  <dd className="flex justify-between items-center text-sm text-gray-900">
                    <div>
                      {lead.data?.data.vehicle?.codConfirmedOn ? "Yes" : "No"}
                    </div>

                    {!lead.data?.data.vehicle?.codCertificateNumber && (
                      lead.data?.data.vehicle?.codConfirmedOn ? (
                        <button
                          type="button"
                          onClick={() => handleCodConfirmed(false)}
                          className="ml-3 inline-flex items-center rounded-md border border-transparent bg-gp-blue-600 px-1 py-1 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gp-blue-700">
                          Toggle
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => handleCodConfirmed(true)}
                          className="ml-3 inline-flex items-center rounded-md border border-transparent bg-gp-blue-600 px-1 py-1 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gp-blue-700">
                          Toggle
                        </button>
                      )
                    )}
                  </dd>
                </div>

                <div className="py-2 grid grid-cols-2 gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    {lead.data?.data.vehicle?.codCertificateNumber ? "COD Certificate Number" : "Generate COD"}
                  </dt>
                  <dd className="flex justify-between items-center text-sm text-gray-900">
                    <span>{lead.data?.data.vehicle?.codCertificateNumber ?? ""}</span>
                    {!lead.data?.data.vehicle?.codCertificateNumber ? (
                      <button
                        type="button"
                        onClick={() => setConfirmCodOpen(true)}
                        className="ml-3 inline-flex items-center rounded-md border border-transparent bg-gp-blue-600 px-1 py-1 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gp-blue-700">
                        Generate
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => downloadCodCertificate()}
                        className="ml-3 inline-flex items-center rounded-md border border-transparent bg-gp-blue-600 px-1 py-1 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gp-blue-700">
                        Download Certificate
                      </button>
                    )}
                  </dd>
                </div>

              </>
            )}

            <div className="py-2 grid grid-cols-2 gap-4">
              <dt className="flex items-center text-sm font-medium text-gray-500">
                Status
              </dt>
              <dd className="text-sm text-gray-900">
                <select
                  // defaultValue={lead.status}
                  value={leadStatus}
                  onChange={(e) => handleStatusChange(e.target.value)}
                  className="border border-gray-400 rounded-md text-sm"
                >
                  {Object.values(LeadStatus).filter((ct) => isNaN(Number(ct))).map((status, i) => (
                    <option key={status} value={i}>
                      {status}
                    </option>
                  ))}
                </select>
              </dd>
            </div>

            <div className="pt-3 flex justify-between items-center">
              <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-3 gap-y-3">
                <div className="flex gap-x-3">
                  <ButtonInput label="Send Collection Note" classes="" isSubmit={false} onClick={() => sendDocuments(true, false)} />

                  <ButtonInput label="Print Collection Note" classes="" isSubmit={false} onClick={() => printCollectionNote()} />
                </div>

                {!lead.data?.data.vehicle?.codCertificateNumber && (
                  <div className="flex gap-x-3">
                    <ButtonInput label="Send COD Certificate" classes="" isSubmit={false} onClick={() => sendDocuments(false, true)} />

                    <ButtonInput label="Send Both" classes="" isSubmit={false} onClick={() => sendDocuments(true, true)} />
                  </div>
                )}
              </div>
              <div>
                <ButtonInput label="Complete Lead" classes={lead.data?.data.completedOn && "bg-green-600 hover:bg-green-700"}
                  isSubmit={false} onClick={() => setConfirmComplete(true)}
                />
              </div>
            </div>
          </div>

        </div>
      </Card >
    </>
  );
}
