import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import useNotification from "../../components/notifications/useNotifications";
import GetAddresses from "../../components/shared/Addresses/GetAddresses";
import Breadcrumbs from "../../components/shared/Breadcrumbs/Breadcrumbs";
import Card from "../../components/shared/Card/Card";
import { ButtonInput } from "../../components/shared/Inputs/ButtonInput";
import { NumberInput } from "../../components/shared/Inputs/NumberInput";
import { TextInput } from "../../components/shared/Inputs/TextInput";
import LoadingWheel from "../../components/shared/LoadingWheel";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import useLeads from "../../data/useLeads";
import useVehiclesMutations from "../../data/useVehiclesMutations";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { INewLead, LeadSource } from "../../models/Lead";

export default function CreateLead() {
  const navigate = useNavigate();
  const { addNotification } = useNotification();
  const { saveHandlers } = useDefaultCRUDHandlers("Lead");
  const { setInfo } = usePageNameContext();
  const { create } = useLeads();
  const { vrmLookup } = useVehiclesMutations();
  const [address, setAddress] = useState<any>();
  const [postcode, setPostcode] = useState("");
  const [currentSource, setCurrentSource] = useState("GPM");
  const [addressesOpen, setAddressesOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { register, getValues, setValue, watch, handleSubmit, reset, control } =
    useForm<any>({
      defaultValues: {
      },
    });

  const sources = [
    ...Object.values(LeadSource).filter((ct) => isNaN(Number(ct))),
  ];

  const onSubmit = (data: any) => {
    setIsLoading(true);

    var newLead: INewLead = {
      CCID: null,
      website: parseInt(currentSource),
      source: "Manual",
      price: data.price,
      commission: data.commission,
      vehicleGrade: data.vehicleGrade,
      vehicle: {
        vrm: data.vrm,
        make: data.make,
        model: data.model,
        postcode: data.postcode,
        line1: data.line1,
        line2: data.line2,
        line3: data.line3,
        line4: data.line4,
        locality: data.locality,
        town: data.town,
        county: data.county
      },
      contact: {
        fullname: data.fullname,
        email: data.email,
        telephone1: data.telephone1,
        telephone2: data.telephone2,
      }
    }

    create.mutate(newLead, {
      onSuccess: (data) => {
        saveHandlers.onSuccess();
        setIsLoading(false);
        navigate("..");
      },
      onError: (error) => {
        saveHandlers.onError();
        setIsLoading(false);
      },
    });
  };

  const handlePostcodeChange = () => {
    if (getValues("postcode") !== postcode) {
      setPostcode(getValues("postcode"));
    } else {
      setAddressesOpen(true);
    }
  }

  const handleVrmLookup = () => {
    vrmLookup.mutate(getValues("vrm"), {
      onSuccess: (data: any) => {
        console.log(data.data)
        addNotification({
          variant: "success",
          primaryText: "Found vehicle",
        });
        setValue("make", data.data.combinedMake);
        setValue("model", data.data.combinedModel);
      },
      onError: (error) => {
        console.error(error);
        addNotification({
          variant: "error",
          primaryText: "Error looking up VRM",
        });
      },
    });
  }

  useEffect(() => {
    setInfo({
      name: "New Lead",
      desc: "Create a new manual lead",
    });
  }, []);

  useEffect(() => {
    if (address) {
      setValue("line1", address.line1);
      setValue("line2", address.line2);
      setValue("line3", address.line3);
      setValue("line4", address.line4);
      setValue("locality", address.locality);
      setValue("town", address.town);
      setValue("county", address.county);
      setValue("postcode", address.postcode);
    }
  }, [address])

  const pages = [
    { to: "..", label: "Leads", current: false },
    {
      to: "",
      label: "New Lead",
      current: true,
    },
  ];

  if (isLoading) {
    return (
      <div className="pt-[20vh] flex justify-center items-center h-full">
        <LoadingWheel />
      </div>
    )
  } else {
    return (
      <>
        <GetAddresses postcode={postcode} setAddress={setAddress} open={addressesOpen} setOpen={setAddressesOpen} />
        <Breadcrumbs pages={pages} />

        <Card containerClassName="mt-4">
          <form onSubmit={handleSubmit(onSubmit)} className="px-6 pt-4 pb-8">
            <h1 className="mb-2 text-lg font-semibold">Lead Details</h1>
            <div className="grid grid-cols-1 gap-x-8 gap-y-4 sm:gap-y-4 sm:grid-cols-2">
              {/* Website select */}
              <NumberInput label="Price" register={register} registerName="price" />
              <NumberInput label="Comission" register={register} registerName="commission" />
              <select aria-label="Contact type filter"
                onChange={(e) => setCurrentSource(e.target.value)}
                className="sm:text-sm">
                {sources.map((source, i) => (
                  <option
                    className="sm:text-sm"
                    key={source}
                    value={i}>
                    {String(source)}
                  </option>
                ))}
              </select>
            </div>

            <h1 className="mt-6 mb-2 text-lg font-semibold">Vehicle Details</h1>
            <div className="mt-4 grid grid-cols-1 gap-x-8 gap-y-4 sm:gap-y-4 sm:grid-cols-2">
              <TextInput label="VRM" register={register} registerName="vrm" />
              <div className="flex items-center mt-7">
                <ButtonInput label="Find Vehicle" isSubmit={false} classes="w-40 h-10" onClick={() => handleVrmLookup()} />
              </div>
            </div>

            <div className="mt-4 grid grid-cols-1 gap-x-8 gap-y-4 sm:gap-y-4 sm:grid-cols-2">
              <TextInput label="Make" register={register} registerName="make" />
              <TextInput label="Model" register={register} registerName="model" />
              <TextInput label="Vehicle Grade" register={register} registerName="vehicleGrade" />
            </div>

            <div className="mt-4 grid grid-cols-1 gap-x-8 gap-y-4 sm:gap-y-4 sm:grid-cols-2">
              <TextInput label="Postcode" register={register} registerName="postcode" />
              <div className="flex items-center mt-7">
                <ButtonInput label="Get Addresses" isSubmit={false} classes="w-40 h-10" onClick={() => handlePostcodeChange()} />
              </div>
            </div>

            <div className="mt-4 grid grid-cols-1 gap-x-8 gap-y-4 sm:gap-y-4 sm:grid-cols-2">
              <TextInput label="Line 1" register={register} registerName="line1" />
              <TextInput label="Line 2" register={register} registerName="line2" />
              <TextInput label="Line 3" register={register} registerName="line3" />
              <TextInput label="Line 4" register={register} registerName="line4" />
              <TextInput label="Locality" register={register} registerName="locality" />
              <TextInput label="Town" register={register} registerName="town" />
              <TextInput label="County" register={register} registerName="county" />
            </div>

            <h1 className="mt-6 mb-4 text-lg font-semibold">Contact Details</h1>
            <div className="grid grid-cols-1 gap-x-8 gap-y-4 sm:gap-y-4 sm:grid-cols-2">
              <TextInput label="Full Name" register={register} registerName="fullname" />
              <TextInput label="Email Address" register={register} registerName="email" />
              <TextInput label="Main Telephone" register={register} registerName="telephone1" />
              <TextInput label="Additional Telephone" register={register} registerName="telephone2" />
            </div>

            <ButtonInput label="Create Lead" isSubmit={true} classes="mt-8" />
          </form>
        </Card>
      </>
    );
  }
}
