export const firebaseAPIKey = process.env.REACT_APP_FIREBASE_API_KEY;
export const resourceAPIURL = process.env.REACT_APP_RESOURCE_API_URL;

export const wilmaSSODashboardUrl =
  process.env.REACT_APP_WILMA_SSO_DASHBOARD_URL;
export const wilmaSSOAPIUrl = process.env.REACT_APP_WILMA_SSO_API_URL;
export const wilmaSSOAppId = process.env.REACT_APP_WILMA_SSO_APP;

export const tokenStorageKey = "wilma-app-token";
export const appRegistrationIdStorageKey = "wilma-application-registration";
