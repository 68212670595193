import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { ISingleContactRespose, IContact } from "../models/Contact";

export default function useContactSingle(contactId: string = "") {
  const { get, post, put, del } = useApiHelper();
  const queryClient = useQueryClient();

  const shouldFetch = contactId != "" && contactId != null;

  const contact = useQuery(
    ["contact", contactId],
    () => get<ISingleContactRespose>(`/contact/${contactId}`),
    {
      enabled: shouldFetch,
    }
  );

  const update = useMutation(
    (_contact: IContact) => put(`/contact/${_contact.id}`, _contact),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["contacts"]);
      },
    }
  );

  return {
    contact,
    update,
  };
}
