import { resourceAPIURL } from "../config";
import { useAuth } from "../contexts/AuthContext";

export default function useApiHelper(
  config = { apiUrl: undefined, headers: {}, options: {} }
) {
  const { token, loggedIn, loading } = useAuth();
  const baseUrl = config.apiUrl || `${resourceAPIURL}/v1`;

  const _getBearer = async () => {
    try {
      if (!loading && loggedIn) {
        return { Authorization: `Bearer ${token}` };
      } else {
        return { Authorization: `Bearer` };
      }
    } catch (err) {
      console.error(err);
      return { Authorization: `Bearer` };
    }
  };

  const _getReqOpts = async () => {
    const reqOpts = {
      headers: {
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "true",
        ...(await _getBearer()),
      },
      ...config.options,
    };

    return reqOpts;
  };

  async function _handleResponse<T>(res: Response): Promise<T> {
    if (!res.ok) {
      let message = `HTTP Error: ${res.status}`;

      if (res.status === 400) {
        try {
          let json = await res.json();
          if (json.message) {
            message = json.message;
          }
        } catch {}
      }

      if (res.status === 401) {
        // refreshToken();
      }

      throw new Error(message);
    }

    let text = await res.text();

    try {
      return JSON.parse(text);
    } catch (err: any) {
      console.warn(text);
      throw new Error(err);
    }
  }

  async function get<T>(path: string) {
    let rawRes = await fetch(`${baseUrl}${path}`, {
      ...(await _getReqOpts()),
      method: "GET",
    });

    return await _handleResponse<T>(rawRes);
  }

  async function getFile<T>(path: string) {
    let rawRes = await fetch(`${baseUrl}${path}`, {
      ...(await _getReqOpts()),
      method: "GET",
    });

    return rawRes.blob();
  }

  async function postGetFile<T>(path: string, body: T) {
    let rawRes = await fetch(`${baseUrl}${path}`, {
      ...(await _getReqOpts()),
      body: JSON.stringify(body),
      method: "POST",
    });

    return rawRes.blob();
  }

  async function put<T>(path: string, body: T) {
    let rawRes = await fetch(`${baseUrl}${path}`, {
      ...(await _getReqOpts()),
      body: JSON.stringify(body),
      method: "PUT",
    });

    let res = await _handleResponse<T>(rawRes);

    return res;
  }

  async function patch<T>(path: string, body: Object) {
    let rawRes = await fetch(`${baseUrl}${path}`, {
      ...(await _getReqOpts()),
      body: JSON.stringify(body),
      method: "PATCH",
    });

    let res = await _handleResponse<T>(rawRes);

    return res;
  }

  async function post<T = any, U = any>(path: string, body: T) {
    let rawRes = await fetch(`${baseUrl}${path}`, {
      ...(await _getReqOpts()),
      body: JSON.stringify(body),
      method: "POST",
    });

    let res = await _handleResponse<U>(rawRes);

    return res;
  }

  async function postRaw<T>(path: string, body: T) {
    let rawRes = await fetch(`${baseUrl}${path}`, {
      ...(await _getReqOpts()),
      body: JSON.stringify(body),
      method: "POST",
    });

    return rawRes;
  }

  async function postDocs<T>(path: string, body: any) {
    let rawRes = await fetch(`${baseUrl}${path}`, {
      body: body,
      headers: {
        ...(await _getBearer()),
      },
      ...config.options,
      method: "POST",
    });

    let res = await _handleResponse<T>(rawRes);

    return res;
  }

  async function del<T>(path: string, body?: any) {
    let rawRes = await fetch(`${baseUrl}${path}`, {
      ...(await _getReqOpts()),
      body: JSON.stringify(body),
      method: "DELETE",
    });

    let res = await _handleResponse<T>(rawRes);

    return res;
  }

  async function customerPostFormData<T>(path: string, body: any) {
    let rawRes = await fetch(`${baseUrl}${path}`, {
      method: "POST",
      headers: { "x-emailId": body.emailId },
      body: body.data,
    });

    let res = await _handleResponse<T>(rawRes);

    return res;
  }

  async function customerPatch<T>(path: string, body: any) {
    let rawRes = await fetch(`${baseUrl}${path}`, {
      headers: {
        "Content-Type": "application/json",
        "x-emailId": body.emailId,
      },
      body: JSON.stringify(body),
      method: "PATCH",
    });

    let res = await _handleResponse<T>(rawRes);

    return res;
  }

  return {
    get,
    getFile,
    put,
    patch,
    post,
    postRaw,
    postDocs,
    postGetFile,
    customerPostFormData,
    customerPatch,
    del,
  };
}
