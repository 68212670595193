import classNames from "../../../utils/classNames"

export const NumberInput = ({ label, register, registerName, step = ".01", classes = "" }) => {

  return (
    <div className={classNames(classes, "sm:col-span-1")}>
      <label className="text-sm font-medium text-gray-500">{label}</label>
      <input {...register(registerName)} type="number" step={step} className="w-full mt-1 text-sm text-gray-900 border-1 border-gray-400" />
    </div>
  )
}