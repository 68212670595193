import classNames from "../../../utils/classNames";

export const ButtonInput = ({
  label = "",
  isSubmit,
  onClick = () => {},
  classes = "",
  disabled = false,
  children = <p></p>,
}) => {
  return (
    <button
      className={classNames(
        "rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-primary-700 disabled:bg-gray-500 disabled:hover:bg-gray-500",
        classes
      )}
      onClick={onClick}
      disabled={disabled}
      type={`${isSubmit ? "submit" : "button"}`}
    >
      {label.length > 0 ? label : children}
    </button>
  );
};
