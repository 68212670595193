import { useEffect, useState } from "react";

import { PhotoIcon, VideoCameraIcon, XCircleIcon } from "@heroicons/react/24/outline";

import useLeadDocuments from "../../data/useLeadDocuments";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import Modal from "../../layouts/Modal";
import { DocumentType, IDocument } from "../../models/Document";
import { ILead } from "../../models/Lead";
import ConfirmModal from "../shared/Confirm/ConfirmModal";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import FileUpload from "../shared/Inputs/FileUpload";
import LoadingWheel from "../shared/LoadingWheel";


export default function LeadDocuments({ lead }: { lead: ILead }) {
  const { leadDocuments, downloadDoc, uploadDoc, deleteDoc } = useLeadDocuments(lead.id);
  const { deleteHandlers } = useDefaultCRUDHandlers("Document")
  const [isLoading, setIsLoading] = useState(true);
  const [uploadIsLoading, setUploadIsLoading] = useState(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [preview, setPreview] = useState("");
  const [isVideo, setIsVideo] = useState(false);
  const [vehicleImages, setVehicleImages] = useState<IDocument[]>([]);
  const [idImages, setIdImages] = useState<IDocument[]>([]);
  const [miscImages, setMiscImages] = useState<IDocument[]>([]);
  const [codImages, setCodImages] = useState<IDocument[]>([]);
  const [collectionNoteImages, setCollectionNoteImages] = useState<IDocument[]>([]);
  const [otherImages, setOtherImages] = useState<IDocument[]>([]);
  const [yardImages, setYardImages] = useState<IDocument[]>([]);
  const [toUpload, setToUpload] = useState([]);
  const [toDelete, setToDelete] = useState("");
  const [docType, setDocType] = useState("0");

  const updateToUploadFiles = (files: any) => setToUpload({ ...files });

  const docTypes = [...Object.values(DocumentType).filter((ct) => isNaN(Number(ct)))];

  const handleOpenDelete = (docId: string) => {
    setToDelete(docId);
    setDeleteOpen(true);
  }

  const handleDelete = () => {
    if (!toDelete) {
      return;
    }
    setDeleteIsLoading(true);

    deleteDoc.mutate(toDelete, {
      onSuccess: () => {
        deleteHandlers.onSuccess();
        setDeleteOpen(false);
      },
      onError: (error) => {
        deleteHandlers.onError();
      },
      onSettled: () => {
        setDeleteIsLoading(false);
      }
    });
  }

  function output(docType: IDocument[]) {
    return docType.map((doc: IDocument) => {
      const image = doc.thumbnail ? (
        <img src={`data:image/png;base64, ${doc.thumbnail!} `} alt={doc.fileName!} className="rounded-sm object-contain w-full h-full" />
      )
        :
        (doc.contentType?.includes("video") ?
          (<VideoCameraIcon className="w-1/2 rounded-sm object-contain" />) :
          (<PhotoIcon className="w-1/2 rounded-sm object-contain" />)
        )

      return (
        <div key={doc.id} className="h-full relative flex flex-col items-center">
          <XCircleIcon className="absolute w-6 h-6 font-bold top-0 right-3 text-red-600 hover:text-red-700 hover:cursor-pointer" onClick={() => handleOpenDelete(doc.id)} />
          <div className="flex justify-center cursor-pointer" onClick={() => handlePreview(doc)}>
            {image}
          </div>
          <div className="text-center w-full">
            <div className="font-semibold">Filename:</div>
            <p className="truncate text-sm">{doc.fileName}</p>
            <button type="button" onClick={() => handleDownload(doc)} className="font-bold text-gp-blue-600">Download</button>
          </div>
        </div>
      )
    })
  }

  const handleDownload = (doc: IDocument) => {
    var ids = {
      leadId: lead.id,
      docId: doc.id
    }

    downloadDoc.mutate(ids, {
      onSuccess: (file: any) => {
        const url = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.type = 'image/png';
        link.download = `${doc.fileName}`;
        link.click();
        URL.revokeObjectURL(url);
      }
    })
  }

  const handlePreview = (doc: IDocument) => {
    var ids = {
      leadId: lead.id,
      docId: doc.id
    }

    downloadDoc.mutate(ids, {
      onSuccess: (file: any) => {
        const url = window.URL.createObjectURL(file);

        if (file.type.includes("image")) {
          setIsVideo(false);
        } else if (file.type.includes("video")) {
          setIsVideo(true);
        }

        setPreview(url);
        setPreviewOpen(true);
      }
    })
  }

  const handleUploadDocs = () => {
    //attach files to http request
    setUploadIsLoading(true);
    const formData = new FormData();

    for (let i = 0; i < Object.keys(toUpload).length; i++) {
      formData.append(DocumentType[parseInt(docType)].toString(), toUpload[i]);
    }

    uploadDoc.mutate(formData, {
      onSuccess: () => {
        setUploadOpen(false);
        setToUpload([]);
      },
      onError: (error) => {
        console.log(error);
      },
      onSettled: () => {
        setUploadIsLoading(false);
      }
    })

  }

  useEffect(() => {
    if (!leadDocuments.isLoading) {
      setVehicleImages(leadDocuments.data?.data?.filter((doc: IDocument) => doc.type === DocumentType["Vehicle"])! || []);
      setIdImages(leadDocuments.data?.data?.filter((doc: IDocument) => doc.type === DocumentType["Identity"])! || []);
      setMiscImages(leadDocuments.data?.data?.filter((doc: IDocument) => doc.type === DocumentType["Misc"])! || []);
      setCodImages(leadDocuments.data?.data?.filter((doc: IDocument) => doc.type === DocumentType["COD"])! || []);
      setCollectionNoteImages(leadDocuments.data?.data?.filter((doc: IDocument) => doc.type === DocumentType["CollectionNote"])! || []);
      setOtherImages(leadDocuments.data?.data?.filter((doc: IDocument) => doc.type === DocumentType["Other"])! || []);
      setYardImages(leadDocuments.data?.data?.filter((doc: IDocument) => doc.type === DocumentType["Yard"])! || []);

      setIsLoading(false);
    }
  }, [lead, leadDocuments.isLoading, leadDocuments.data])

  if (isLoading) {
    return (
      <div className="flex items-center py-[10vh] bg-white rounded shadow">
        <LoadingWheel />
      </div>
    )
  } else {
    return (
      <>
        <ConfirmModal open={deleteOpen} setOpen={setDeleteOpen}
          title={"Delete Document?"} message={`Are you sure you want to delete this document? \nThis is a permanent action and cannot be undone.`} confirmButtonText={"Delete"}
          onConfirm={() => handleDelete()} isLoading={deleteIsLoading}
        />
        <Modal open={uploadOpen} setOpen={setUploadOpen} width="max-w-4xl" >
          <div>
            <h1 className="text-xl font-semibold">Upload Documents</h1>
            {uploadIsLoading ? (
              <div className="flex items-center py-[10vh]">
                <LoadingWheel />
              </div>
            ) : (
              <>
                <div className="mt-4 pb-4">
                  <label>Document Type</label>
                  <select onChange={(e) => setDocType(e.target.value)} value={docType} className="w-full text-sm text-gray-900">
                    {docTypes.map((dt, i) => (
                      <option key={dt} value={i}>
                        {dt}
                      </option>
                    ))}
                  </select>
                </div>
                <FileUpload accept=".jpg,.png,.jpeg,.pdf" label="Upload Images" desc="Make sure to select a document type" multiple updateFilesCb={updateToUploadFiles} />
                <div className="flex justify-between mt-4">
                  <ButtonInput classes="!bg-white border border-gray-200 !text-gp-blue-500 hover:!bg-gray-50" label="Cancel" isSubmit={false} onClick={() => setUploadOpen(false)} />
                  <ButtonInput classes="" label="Upload" isSubmit={false} onClick={() => handleUploadDocs()} />
                </div>
              </>
            )}
          </div>
        </Modal>
        <Modal open={previewOpen} setOpen={setPreviewOpen} width="max-w-4xl">
          <div className="flex justify-center">
            {isVideo ? (
              <video width="1024" height="576" controls>
                <source src={preview} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img src={preview} alt="preview" className="w-full" />
            )}
          </div>
        </Modal>
        <div className="p-4 bg-white rounded shadow">
          <div className="flex justify-between">
            <h1 className="text-xl font-semibold">Lead Documents <span className="text-gray-600 text-sm font-medium">- Click on a picture to preview it.</span></h1>
            <ButtonInput classes="" label="Upload" isSubmit={false} onClick={() => setUploadOpen(true)} />
          </div>
          <h3 className="mt-2 border-t pt-4 text-lg font-semibold">Vehicle Images</h3>
          <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-6">
            {output(vehicleImages)}
          </div>

          <h3 className="mt-2 border-t pt-4 text-lg font-semibold">Identity Images</h3>
          <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-6">
            {output(idImages)}
          </div>

          <h3 className="mt-2 border-t pt-4 text-lg font-semibold">Miscellaneous Images</h3>
          <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-6">
            {output(miscImages)}
          </div>

          <h3 className="mt-2 border-t pt-4 text-lg font-semibold">COD Certificate</h3>
          <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-6">
            {output(codImages)}
          </div>

          <h3 className="mt-2 border-t pt-4 text-lg font-semibold">Collection Note</h3>
          <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-6">
            {output(collectionNoteImages)}
          </div>

          <h3 className="mt-2 border-t pt-4 text-lg font-semibold">Other Images</h3>
          <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-6">
            {output(otherImages)}
          </div>

          <h3 className="mt-2 border-t pt-4 text-lg font-semibold">Yard Images</h3>
          <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-6">
            {output(yardImages)}
          </div>
        </div>
      </>
    )
  }
}