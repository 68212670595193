import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { ILead, ISingleLeadRespose } from "../models/Lead";

import leadsStub from "../stubs/leads.json"

export default function useSingleLead(leadId: string) {
  const { get, getFile, post, put, del } = useApiHelper();
  const queryClient = useQueryClient();

  const lead = useQuery({
    queryKey: ["lead", leadId],
    queryFn: () => get<ISingleLeadRespose>(`/lead/${leadId}`),
    // queryFn: () => ({
    //   data: leadsStub.find((lead) => lead.id === leadId) as unknown as ILead,
    // }),
    staleTime: 1000 * 30, // 30 seconds
    refetchInterval: 1000 * 60 * 10, // 10 minutes
  });

  const update = useMutation((lead: ILead) => put(`/lead/${leadId}`, lead), {
    onMutate: async (lead: ILead) => {
      await queryClient.cancelQueries({ queryKey: ["leads"] });
      const previousLead = queryClient.getQueryData(["leads", lead.id]);
      queryClient.setQueryData(["leads", lead.id], lead);
      return { previousLead, lead };
    },
    onError: (err, lead, context) => {
      queryClient.setQueryData(
        ["leads", context?.lead.id],
        context?.previousLead
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(["lead", leadId]);
      queryClient.invalidateQueries(["leads"]);
      queryClient.invalidateQueries(["raisedPayments"]);
      queryClient.invalidateQueries(["unscheduledLeads"]);
      queryClient.invalidateQueries(["scheduledLeads"]);
    },
  });

  const remove = useMutation((leadId: string) => del(`/lead/${leadId}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(["leads"]);
    },
  });

  const complete = useMutation(
    (leadId: string) => post(`/lead/${leadId}/complete`, null),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["lead", leadId]);
        queryClient.invalidateQueries(["leads"]);
      },
    }
  );

  const downloadDoc = useMutation(
    (ids: any) => getFile(`/lead/${ids.leadId}/documents/${ids.docId}`),
    {
      onSuccess: (data: any) => {
        return data;
      },
    }
  );

  const getCollectionNote = useMutation(
    (leadId: string) => getFile(`/lead/${leadId}/documents/note`),
    {
      onSuccess: (data: any) => {
        return data;
      },
    }
  );

  return {
    lead,
    update,
    remove,
    complete,
    downloadDoc,
    getCollectionNote,
  };
}
