import { MouseEventHandler, ReactNode } from "react";
import clsx from "clsx";

export interface ButtonProps {
  children?: ReactNode;
  type?: "submit" | "reset" | "button";
  className?: string;
  fullWidth?: boolean;
  variant?: "contained" | "outlined";
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

export function Button({
  children,
  className,
  type = "button",
  fullWidth = false,
  variant = "contained",
  onClick,
  disabled = false,
}: ButtonProps) {
  let sizeClass: String;
  let styleClass: String;

  sizeClass = "px-3 py-2 font-medium text-sm";

  if (variant === "contained") {
    styleClass =
      "bg-primary-500 text-white border border-primary-500 hover:bg-primary-600 hover:border-primary-600 active:border-primary-700 active:bg-primary-700";
  } else {
    styleClass =
      "border border-primary text-gray-700 hover:bg-primary/20 active:bg-primary/10";
  }

  return (
    <button
      type={type}
      onClick={disabled ? () => {} : onClick}
      className={clsx(
        "rounded",
        fullWidth && "w-full",
        sizeClass,
        styleClass,
        className,
        disabled &&
          "!bg-gray-200 !border-gray-200 !text-gray-400 dark:!bg-gray-400 dark:!border-gray-400 dark:!text-gray-600"
      )}
    >
      {children}
    </button>
  );
}
