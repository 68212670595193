import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IPostcodeGroup, IPostcodeGroupResponse } from './../models/PostcodeGroup';

export default function usePostcodeGroupSingle(postcodeGroupId: string) {
  const { get, post, put, patch, del } = useApiHelper();
  const queryClient = useQueryClient();

  const postcodeGroup = useQuery({
    queryKey: ["postcodeGroups", postcodeGroupId],
    queryFn: () => get<IPostcodeGroupResponse>(`/postcodePrice/group/${postcodeGroupId}/prices`),
    refetchInterval: 1000 * 10,
  });

  const create = useMutation(
    (postcodeGroup: IPostcodeGroup) => post("/postcodePrice/group", postcodeGroup),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["postcodeGroups"]);
      },
    }
  );

  const update = useMutation(
    (postcode: IPostcodeGroup) => patch(`/postcodePrice/group`, postcode),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["postcodeGroups"]);
      },
    }
  );

  const remove = useMutation(
    (postcodeId: string) => del(`/postcodePrice/group/${postcodeId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["postcodeGroups"]);
      },
    }
  );

  return {
    postcodeGroup,
    create,
    update,
    remove,
  };
}
