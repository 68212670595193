import { useQuery } from "@tanstack/react-query";

import { useAuthApi } from "../hooks/useAuthApi";

export default function useWilmaUsers() {
  const { getUsers } = useAuthApi();

  const wilmaUsers = useQuery({
    queryKey: ["wilmaUsers"],
    queryFn: () => getUsers(),
    refetchInterval: 1000 * 10,
  });

  return {
    wilmaUsers,
  };
}
