
import { Label, Listbox, ListboxOption, ListboxOptions, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import classNames from "../../../utils/classNames";

export default function SelectInput({ label, register, registerName, options, setValue, watch, cols = "sm:col-span-1" }) {

  const selectValue = watch(registerName);

  //options = [
  //  { id: 1, name: "Option 1" },
  //  { id: 2, name: "Option 2" },
  //]

  // console.log(registerName, selectValue);

  return (
    <div className={cols}>
      <Listbox
        {...register(registerName)}
        value={selectValue}
        onChange={(e) => setValue(registerName, e)}
      >
        {({ open }) => (
          <>
            <Label className="block mb-2 text-sm font-medium text-gray-700">
              {label}
            </Label>
            <div className="w-full relative">
              <Listbox.Button className={classNames("relative w-full text-sm text-left cursor-pointer border p-2 bg-white border-1 border-gray-400")}>
                <span className="block truncate">
                  {
                    options.length > 0 &&
                    options.find(
                      (e) => e.id === selectValue
                    )?.name
                  }
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-700"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                appear={true}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <ListboxOptions className="absolute z-20 text-sm mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 ring-1 ring-black ring-opacity-5 overflow-auto">
                  {options.map((e) => (
                    <ListboxOption
                      key={e.id}
                      className={({ active }) =>
                        classNames(
                          active
                            ? "bg-primary-100 text-gray-900"
                            : "text-gray-900",
                          "cursor-default select-none relative py-2 pl-8 pr-4"
                        )
                      }
                      value={e.id}
                    >
                      <>
                        <span
                          className={classNames(
                            selectValue === e.id
                              ? "font-semibold"
                              : "font-normal",
                            "block truncate"
                          )}
                        >
                          {e.name}
                        </span>

                        {selectValue === e.id ? (
                          <span className="text-primary-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                            <CheckIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  )
}