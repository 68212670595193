import classNames from "../../../utils/classNames"

export const TextInput = ({
  label,
  register,
  registerName,
  placeholder = "",
  disabled = false,
  type = "text",
  richText = false,
  cols = "sm:col-span-1",
  customClasses = "",
  rows = 2,
  initialValue = "",
  maxLength = 524288,
}) => {

  return (
    <div className={cols}>
      <label className="text-sm font-medium text-gray-500">
        {label}
      </label>
      {
        richText ?
          <textarea
            {...register(registerName)}
            disabled={disabled}
            className={classNames(disabled ? "bg-gray-300" : "", `w-full mt-1 text-sm text-gray-900 border-1 border-gray-400 ${customClasses}`)}
            rows={rows}
            defaultValue={initialValue}
            placeholder={placeholder}
          />
          :
          <input
            {...register(registerName)}
            disabled={disabled}
            type={type}
            className={classNames(disabled ? "bg-gray-300" : "", `w-full mt-1 text-sm text-gray-900 border-1 border-gray-400 ${customClasses}`)}
            defaultValue={initialValue}
            maxLength={maxLength}
            placeholder={placeholder}
          />
      }
    </div>
  )
}