import { ICustomer } from "./ICustomer";
import { BaseEntity, DefaultResponseWithData } from "./system";


export interface IAllPriceSettingsResponse extends DefaultResponseWithData<AllPriceSettings> { };


export interface AllPriceSettings {
  customerPriceConfiguration: ICustomerPriceConfiguration | null;
  genericPriceSettings: ICustomerGenericPriceSetting[];
  salvagePriceSettings: ICustomerSalvagePriceSetting[];
}


export interface ICustomerPriceConfiguration extends BaseEntity {
  doesCollection: boolean;
  doesDropOff: boolean;
  customerId: string;
  customer: ICustomer | null;
  collectionPriceSettingId: string | null;
  collectionPriceSetting: IPriceSetting | null;
  dropOffPriceSettingId: string | null;
  dropOffPriceSetting: IPriceSetting | null;
  alloyWheelsPriceSettingId: string | null;
  alloyWheelsPriceSetting: IPriceSetting | null;
  noFaultPriceSettingId: string | null;
  noFaultPriceSetting: IPriceSetting | null;
  catalyticConverterPriceSettingId: string | null;
  catalyticConverterPriceSetting: IPriceSetting | null;
  exportPriceSettingId: string | null;
  exportPriceSetting: IPriceSetting | null;
}


export interface ICustomerGenericPriceSetting extends BaseEntity {
  type: GenericPriceSettingType;
  value: string;
  customerPriceConfigurationId: string;
  customerPriceConfiguration: ICustomerPriceConfiguration | null;
  priceSettingId: string;
  priceSetting: IPriceSetting | null;
}


export interface ICustomerSalvagePriceSetting extends BaseEntity {
  engineCondition: EngineCondition;
  bodyCondition: BodyCondition;
  customerPriceConfigurationId: string;
  customerPriceConfiguration: ICustomerPriceConfiguration | null;
  priceSettingId: string;
  priceSetting: IPriceSetting | null;
}


export interface IPriceSetting extends BaseEntity {
  isFixed: boolean;
  value: number;
}




export enum GenericPriceSettingType {
  Make,
  Model,
  Year,
  EngineCode,
  FuelType
}

export enum EngineCondition {
  NoFault,
  MinorFault,
  MajorFault
}

export enum BodyCondition {
  Good,
  Average,
  HeavyDamage
}