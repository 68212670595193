import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "../../contexts/AuthContext";
import { wilmaSSODashboardUrl } from "../../config";

interface IAllowedRoles {
  isDriver?: boolean;
  isStaff?: boolean;
  isAdmin?: boolean;
  isMechanic?: boolean;
}

export default function RequireAuth({
  allowedRoles,
}: {
  allowedRoles: IAllowedRoles;
}) {
  const { loading, loggedIn, claims } = useAuth();

  const hasAllowedRole = () =>
    Object.keys(allowedRoles).some(
      (role) =>
        allowedRoles[role as keyof IAllowedRoles] &&
        claims![role as keyof IAllowedRoles]
    );

    return <Outlet />; 

  // if (!loading && loggedIn && hasAllowedRole()) {
  //   //Logged in with correct claims
  //   return <Outlet />; 
  // } else if (!loading && loggedIn && !hasAllowedRole()) {
  //   //Logged in but no correct claims
  //   return <Navigate to="/" replace />; 
  // } else if (!loading && !loggedIn) {
  //   //Not logged in, redirect to SSO login
  //   window.location.assign(
  //     `${wilmaSSODashboardUrl}?ari=${wilmaSSOAppRegistration}&returnUrl=${window.location.href}`
  //   );
  //   return <></>;
  // } else {
  //   return <></>;
  // }
}
