import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import PillTab from "../../components/shared/PillTab/PillTab";
import PillTabMenu from "../../components/shared/PillTab/PillTabMenu";

import { usePageNameContext } from "../../contexts/PageTitleContext";

export default function Settings() {
  const { setInfo } = usePageNameContext();
  const navigate = useNavigate();
  const [tabs, setTabs] = useState([
    { label: "General", link: "", active: true },
    { label: "Mail Importer", link: "mail-importer", active: false },
  ]);

  useEffect(() => {
    setInfo({
      name: `Settings`,
      desc: `View and modify system settings here`,
    });
  }, [setInfo]);

  const handleTabClick = (index: number) => {
    let newTabs = tabs.map((tab) => ({ ...tab, active: false }));
    newTabs[index].active = true;
    setTabs(newTabs);
    navigate(newTabs[index].link);
  };

  return (
    <>
      <PillTabMenu ariaLabel="Settings tabs">
        {tabs.map((tab, i) => (
          <PillTab
            key={tab.link}
            label={tab.label}
            active={tab.active}
            onClick={() => handleTabClick(i)}
          />
        ))}
      </PillTabMenu>
      <Outlet />
    </>
  );
}
