import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";

import { IAllVehicleResponse, IVehicle } from "../models/Vehicle";

export default function useVehiclesMutations() {
  const { get, post, put } = useApiHelper();
  const queryClient = useQueryClient();

  const vrmLookup = useMutation(
    (vrm: string) => get(`/vehicle/lookup/${vrm}/`),
    {
      onSuccess: () => {
        
      },
    }
  );

  const update = useMutation(
    (vehicle: IVehicle) => put(`/vehicle/${vehicle.id}`, vehicle),
    {
      onMutate: (vehicle: IVehicle) => {
        queryClient.cancelQueries(["vehicles"]);
        const previousVehicle = queryClient.getQueryData(["vehicles", vehicle.id]);
        queryClient.setQueryData(["vehicles", vehicle.id], vehicle);
        return { previousVehicle, vehicle };
      },
      onError: (err, vehicle, context) => {
        queryClient.setQueryData(
          ['vehicles', context?.vehicle.id],
          context?.previousVehicle,
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(["vehicles"]);
        // queryClient.invalidateQueries(["leads"]);
      },
    }
  );

  const bulkUpdate = useMutation(
    (body: any) => post(`/vehicle/bulk`, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["leads"]);
        queryClient.invalidateQueries(["vehicles"]);
      },
    }
  );

  const vrmRefresh = useMutation(
    (vehicle: IVehicle) => post(`/vehicle/${vehicle.id}/refresh`, { "vrm": vehicle.vrm }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["vehicles"]);
      },
    }
  );

  return {
    vrmLookup,
    update,
    bulkUpdate,
    vrmRefresh,
  };
}
