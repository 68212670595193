import { useState } from "react";

import { Label, Radio, RadioGroup, RadioGroupOption } from "@headlessui/react";

import Modal from "../../layouts/Modal";
import { IPostcodeGroup } from "../../models/PostcodeGroup";
import classNames from "../../utils/classNames";
import AddEditPostcodeGroup from "./AddEditPostcodeGroup";
import PostcodeAreasTable from "./PostcodeAreasTable";
import EditPostcodeAreas from "./EditPostcodeAreas";

interface PostcodeGroupsModalProps {
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  postcodeGroup?: IPostcodeGroup;
}

export default function PostcodeGroupsModal({ open, setOpen, postcodeGroup }: PostcodeGroupsModalProps) {

  const [viewType, setViewType] = useState(0);
  const viewTypes = [
    { value: 0, label: "Postcode Group" },
    { value: 1, label: "Postcode Areas" },
  ];

  return (
    <Modal width="max-w-2xl" open={open} setOpen={setOpen} >

      {postcodeGroup ? (
        <>

          <RadioGroup value={viewType} onChange={setViewType} className="my-4">
            <Label className="sr-only">
              Choose which view to display
            </Label>
            <div className="grid grid-cols-2 lg:grid-cols-2 gap-3">
              {viewTypes.map((type) => (
                <RadioGroupOption
                  key={`${type.value}`}
                  value={type.value}
                  className={({ active, checked }) =>
                    classNames(
                      active ? "ring-2 ring-offset-2 ring-gp-blue-500" : "",
                      checked
                        ? "bg-primary-600 border-transparent text-white hover:bg-gp-blue-700"
                        : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                      "cursor-pointer focus:outline-none border rounded-md py-1.5 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1"
                    )
                  }
                >
                  <Label as="span">{type.label}</Label>
                </RadioGroupOption>
              ))}
            </div>
          </RadioGroup>

          {{
            0: <AddEditPostcodeGroup postcodeGroup={postcodeGroup} setOpen={setOpen} />,
            1: <EditPostcodeAreas postcodeGroupId={postcodeGroup?.id!} />
          }[viewType]}
        </>
      ) : (
        <AddEditPostcodeGroup postcodeGroup={postcodeGroup} setOpen={setOpen} />
      )}
    </Modal>
  );
}
