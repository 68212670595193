import { useState } from "react";

import { NavLink } from "react-router-dom";

import { ChevronLeftIcon } from "@heroicons/react/24/outline";

import classNames from "../../../utils/classNames";
import WilmaLogo from "../../../assets/wilma-logo.svg";
import { NavigationItemInterface } from "../../../types/Navigation";

export interface DesktopSidebarProps {
  navigation: NavigationItemInterface[];
}

function getNavItem(item: NavigationItemInterface) {
  if (item.disabled) {
    return (
      <div className="group flex items-center px-2 py-2 text-sm text-gray-400 font-medium rounded-md select-none">
        <item.icon
          className="mr-3 h-6 w-6 flex-shrink-0 text-currentColor"
          aria-hidden="true"
        />
        {item.name}
      </div>
    );
  }

  return (
    <NavLink
      key={item.name}
      to={item.to}
      className={({ isActive }) =>
        classNames(
          "group flex items-center px-2 py-2 text-sm font-medium rounded-md text-white",
          isActive ? "bg-primary-700" : ""
        )
      }
    >
      <item.icon
        className="mr-3 h-6 w-6 flex-shrink-0 text-currentColor"
        aria-hidden="true"
      />
      {item.name}
    </NavLink>
  );
}

export default function DesktopSidebar({ navigation }: DesktopSidebarProps) {
  let groups = [...new Set(navigation.map((x) => x.group))];
  const [groupToggle, setGroupToggle] = useState(
    Array.from({ length: 10 }, () => true)
  );

  const toggleGroup = (i: number) => {
    let ng = [...groupToggle];
    ng[i] = !ng[i];
    setGroupToggle(ng);
  };

  return (
    <div className="hidden md:!fixed md:!inset-y-0 md:!flex md:!w-60 md:!flex-col">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex flex-grow flex-col overflow-y-auto bg-primary-600 pt-5">
        <div className="flex flex-shrink-0 justify-center items-center px-4 mt-4 mb-8">
          <img className="h-8 w-auto" src={WilmaLogo} alt="Wilma Logo" />
        </div>
        <div className="mt-5 flex flex-col mb-8">
          {groups.map((group, i) => (
            <nav key={i} className="flex-1 space-y-1 px-2 pb-4">
              {group === null || group === undefined ? (
                <></>
              ) : (
                <div
                  className="flex flex-row items-center px-2 text-md font-medium rounded-md text-currentColor cursor-pointer text-primary-100"
                  // style={{ color: "rgb(170 214 253 / 1)" }}
                  onClick={() => toggleGroup(i)}
                >
                  <span>{group}</span>
                  <span
                    className="w-4 h-4 ml-2 transition duration-500"
                    style={{
                      transform: groupToggle[i]
                        ? "rotate(270deg)"
                        : "rotate(90deg)",
                    }}
                  >
                    <ChevronLeftIcon />
                  </span>
                </div>
              )}
              {groupToggle[i]
                ? navigation
                    .filter((x) => x.group === group)
                    .map((n) => getNavItem(n))
                : null}
            </nav>
          ))}
        </div>
      </div>
    </div>
  );
}
