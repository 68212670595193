import { NumberInput } from "../../shared/Inputs/NumberInput";
import SelectInput from "../../shared/Inputs/SelectInput";


interface IGeneralPriceSettingsProps {
  label: string;
  objectName: string;
  register: any;
  setValue: any;
  watch: any;
}

export default function GeneralPriceSettingSingle({ label, objectName, register, setValue, watch }: IGeneralPriceSettingsProps) {

  const options = [
    { id: true, name: "Value" },
    { id: false, name: "Percentage" }
  ]

  return (
    <div className="flex items-center gap-x-4">
      <NumberInput label={label} registerName={`${objectName}.value`} register={register} classes="w-64" />
      <SelectInput label="" register={register} registerName={`${objectName}.isFixed`} options={options} setValue={setValue} watch={watch} cols="pt-5 w-64" />
    </div>
  )
}