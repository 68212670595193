import { useEffect } from "react";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import useSettings from "../../data/useSettings";
import AdminSettingsTable from "../../components/adminSettings/AdminSettingsTable";
import LoadingWheel from "../../components/shared/LoadingWheel";


export default function AdminCredentials() {
  const { setInfo } = usePageNameContext();
  const { saveHandlers } = useDefaultCRUDHandlers("Admin Settings");
  const { settings, update } = useSettings();

  useEffect(() => {
    setInfo({
      name: "Admin Credentials",
      desc: "View and configure your admin credentials",
    });
  }, []);

  useEffect(() => {

  }, [settings.data, settings.isLoading])

  // if (settings.isLoading) {
    return (
      <div>
        <LoadingWheel />
      </div>
    )
  // } else {
    return (
      <div>
        <AdminSettingsTable settings={settings.data?.data!} />
      </div>
    )
  // }
}