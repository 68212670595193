import { useState } from "react";

import date from "date-and-time";

import useMessages from "../../../../data/useMessages";
import { ILead, LeadSource } from "../../../../models/Lead";
import { QualifyLead } from "../../../../services/TemplateService";
import useNotification from "../../../notifications/useNotifications";
import { ButtonInput } from "../../../shared/Inputs/ButtonInput";
import formatDateTime from './../../../../utils/formatDateTime';
import { DateTime } from "luxon";
import PriceFormat from "../../../../utils/priceFormat";

export default function LeadsTableSubComponent({ lead }: { lead: ILead }) {
  const { sendText } = useMessages();
  const { addNotification } = useNotification();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleQualifyText = () => {

    const body = {
      leadId: lead.id,
      templateId: QualifyLead.Message,
      contactId: lead.contact?.id,
      recipient: lead.contact?.telephone1,
      substitutions: {
        "{{name}}": lead.contact?.fullname!,
        "{{make}}": lead.vehicle?.combinedMake!,
        "{{model}}": lead.vehicle?.combinedModel!,
        "{{telephone}}": lead.contact?.telephone1!,
        "{{price}}": lead.price.toString()!,
      },
    };

    sendText.mutate(body, {
      onSuccess: () => {
        setConfirmOpen(false);
        addNotification({
          variant: "success",
          primaryText: "Text sent successfully",
        });
      },
      onError: () => {
        addNotification({
          variant: "error",
          primaryText: "Error sending text",
        });
      },
    });
  }

  return (
    <>
      <div className="flex justify-between bg-gray-100 pl-28 p-4">
        <div className="flex gap-x-10">
          <div className="flex flex-col">
            <span className="font-bold">Price</span>
            <span>{PriceFormat(lead.price)}</span>
          </div>

          <div className="flex flex-col">
            <span className="font-bold">Website</span>
            <span>{LeadSource[lead.website]}</span>
          </div>

          <div className="flex flex-col">
            <span className="font-bold">Exported</span>
            <span className="flex justify-center">{lead.exportedOn !== null ? "✔" : "X"}</span>
          </div>

          <div className="flex flex-col">
            <span className="font-bold">Created</span>
            <span className="flex justify-center">{formatDateTime(lead.created, DateTime.DATE_SHORT)}</span>
          </div>

          <div className="flex flex-col">
            <span className="font-bold">Engine Price</span>
            <span>
              {lead.vehicle?.enginesWorldwideEngineOffer ?
                PriceFormat(parseFloat(lead.vehicle?.enginesWorldwideEngineOffer))
                : "N/A"
              }
            </span>
          </div>

          <div className="flex flex-col">
            <span className="font-bold">Front-end Price</span>
            <span>
              {lead.vehicle?.enginesWorldwideVfeOffer ?
                PriceFormat(parseFloat(lead.vehicle?.enginesWorldwideVfeOffer))
                : "N/A"
              }
            </span>
          </div>

          <div className="flex flex-col">
            <span className="font-bold">Average Catalytic Price</span>
            <span>
              {lead.vehicle?.enginesWorldwideVfeOffer ?
                PriceFormat(parseFloat(lead.catalyticAveragePrice?.toString()!))
                : "N/A"
              }
            </span>
          </div>
        </div>

        <div className="flex items-center">
          <ButtonInput label={"Send Qualifier Text"} isSubmit={false} onClick={handleQualifyText} classes="" />
        </div>

      </div>
    </>
  )
}