import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import usePriceSettings from "../../data/usePriceSettings";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { ICustomerGenericPriceSetting, ICustomerPriceConfiguration } from "../../models/PriceSettings";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import SwitchInput from "../shared/Inputs/SwitchInput";
import GeneralPriceSettingSingle from "./PriceConfiguration/GeneralPriceSettingSingle";
import LoadingWheel from "../shared/LoadingWheel";
import SpecificPriceSingle from "./SpecificConfiguration/SpecificPriceSingle";


export default function SpecificPriceSettings() {
  const { priceSettings } = usePriceSettings();
  const { saveHandlers } = useDefaultCRUDHandlers("Price Settings");
  const [isLoading, setIsLoading] = useState(true);
  const [specificPriceSettings, setSpecificPriceSettings] = useState<ICustomerGenericPriceSetting[]>([]);

  useEffect(() => {
    if (!priceSettings.isLoading) {
      if (priceSettings.data?.data.genericPriceSettings && priceSettings.data?.data.genericPriceSettings.length > 0) {
        setSpecificPriceSettings(priceSettings.data?.data.genericPriceSettings)
      }
      setIsLoading(false);
    }
  }, [priceSettings.data, priceSettings.isLoading])

  if (isLoading) return <LoadingWheel />

  return (
    <div className="bg-white shadow rounded p-4">

      <h1 className="text-xl font-semibold">Specific</h1>
      <hr />

      <div className="mt-4 flex flex-col divide-y gap-y-6">

        {specificPriceSettings.map((priceSetting) => (
          <SpecificPriceSingle key={priceSetting.id} customerPriceConfigurationId={priceSettings.data?.data.customerPriceConfiguration?.id!} specificPriceSetting={priceSetting} />
        ))}

        <h1 className="text-lg font-semibold pt-8">New Price Setting</h1>
        <SpecificPriceSingle key={0} customerPriceConfigurationId={priceSettings.data?.data.customerPriceConfiguration?.id!} />

      </div>
    </div>
  )
}