import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IDocument, IDocumentResponse } from "../models/Document";

export default function useLeadDocuments(leadId: string) {
  const { get, getFile, postDocs, del } = useApiHelper();
  const queryClient = useQueryClient();

  const leadDocuments = useQuery({
    queryKey: ["leadsDocuments", leadId],
    queryFn: () => get<IDocumentResponse>(`/lead/${leadId}/documents`),
    staleTime: 1000 * 60 * 1, // 1 minute
    enabled: !!leadId,
  });


  const downloadDoc = useMutation(
    (ids: any) => getFile(`/lead/${ids.leadId}/documents/${ids.docId}`),
    {
      onSuccess: (data: any) => {
        return data;
      },
    }
  );

  const uploadDoc = useMutation(
    (data: any) => postDocs(`/lead/${leadId}/documents`, data),
    {
      onSuccess: (data: any) => {
        queryClient.invalidateQueries(["leadsDocuments", leadId]);
        return data;
      },
    }
  );

  const deleteDoc = useMutation(
    (docId: any) => del(`/lead/${leadId}/documents/${docId}`),
    {
      onSuccess: (data: any) => {
        queryClient.refetchQueries(["leadsDocuments", leadId]);
        return data;
      },
    }
  );

  return {
    leadDocuments,
    downloadDoc,
    uploadDoc,
    deleteDoc,
  };
}
